import app from 'flarum/forum/app';
import Widget from 'flarum/extensions/afrux-forum-widgets-core/common/components/Widget';

export default class HtmlWidget01 extends Widget {
    oncreate(_vnode) {
        document.getElementById('wgh01').innerHTML = app.forum.attribute('iptvx-html-widget-01.customCode');
    }
    className() { return 'iptvx-html-widget-01'; }
    icon() { return 'fas fa-laptop-code'; }
    content() { return <div id="wgh01" class="htmlwidget"></div>; }
}
