import Widgets from 'flarum/extensions/afrux-forum-widgets-core/common/extend/Widgets';
import HtmlWidget01 from './components/HtmlWidget01';

export default function (app) {
    new Widgets()
        .add({
            key: 'HtmlWidget01',
            component: HtmlWidget01,
            isDisabled: false,
            isUnique: true,
            placement: 'end',
            position: 1
        })
        .extend(app, 'iptvx-html-widget-01');
}
