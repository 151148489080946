import app        from 'flarum/forum/app';
import Component  from 'flarum/common/Component';
import Tooltip    from 'flarum/common/components/Tooltip';
import icon       from 'flarum/common/helpers/icon';

export default class PostedOn extends Component {
  oninit(vnode) {
    super.oninit(vnode);
  }

  view() {
    const post = this.attrs.post;

    if (post.postedOn() === '' || post.postedOn() === null) return;

    return (
      <Tooltip text={this.getPostedOn(post)}>
        <span className="PostedOn">
          {icon(this.getIcon(post))} {post.postedOn()}
        </span>
      </Tooltip>
    );
  }

  getPostedOn(post) {
    return app.translator.trans('iptvx-posted-on.forum.post.posted_on_text', {
      posted_on: post.postedOn(),
    });
  }

  getIcon(post) {
    switch (post.postedOn()) {
      case 'Windows':
        return 'fab fa-windows';
      case 'Windows 11':
        return 'fab fa-windows';
      case 'Windows 10':
        return 'fab fa-windows';
      case 'Windows 8.1':
        return 'fab fa-windows';
      case 'Windows 8':
        return 'fab fa-windows';
      case 'Windows 7':
        return 'fab fa-windows';
      case 'Windows Vista':
        return 'fab fa-windows';
      case 'Windows XP':
        return 'fab fa-windows';
      case 'Windows 2000':
        return 'fab fa-windows';
      case 'Windows ME':
        return 'fab fa-windows';
      case 'Windows 98':
        return 'fab fa-windows';
      case 'Windows 95':
        return 'fab fa-windows';
      case 'Windows 3.5':
        return 'fab fa-windows';
      case 'Windows 3.2':
        return 'fab fa-windows';
      case 'Windows 3.1':
        return 'fab fa-windows';
      case 'Windows 3.0':
        return 'fab fa-windows';
      case 'Android':
        return 'fab fa-android';
      case 'Android 4':
        return 'fab fa-android';
      case 'Android 5':
        return 'fab fa-android';
      case 'Android 6':
        return 'fab fa-android';
      case 'Android 7':
        return 'fab fa-android';
      case 'Android 8':
        return 'fab fa-android';
      case 'Android 9':
        return 'fab fa-android';
      case 'Android 10':
        return 'fab fa-android';
      case 'Android 11':
        return 'fab fa-android';
      case 'Android 12':
        return 'fab fa-android';
      case 'Android 13':
        return 'fab fa-android';
      case 'Android 14':
        return 'fab fa-android';
      case 'Android 15':
        return 'fab fa-android';
      case 'Android 16':
        return 'fab fa-android';
      case 'Android 17':
        return 'fab fa-android';
      case 'Android 18':
        return 'fab fa-android';
      case 'Android 19':
        return 'fab fa-android';
      case 'iPhone':
        return 'fab fa-apple';
      case 'iPad':
        return 'fab fa-apple';
      case 'BlackBerry':
        return 'fab fa-blackberry';
      case 'Ubuntu':
        return 'fab fa-ubuntu';
      case 'Linux':
        return 'fab fa-linux';
      case 'macOS':
        return 'fab fa-apple';
      case 'Mobile':
        return 'fas fa-mobile-alt';
      case 'Bot':
        return 'fas fa-robot';
      default:
        return 'fas fa-globe';
    }
  }
}
