
import app from 'flarum/common/app';
import addTextEditorButton from './addTextEditorButton';

app.initializers.add(
  'iptvx-more-bbcode',
  () => {
    addTextEditorButton();
  },
  -500 // err500
);
